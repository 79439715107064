<template>
  <v-container fluid>
    <v-row class="mx-auto">
      <v-col
        xs="12"
        sm="6"
        md="4"
        xl="3"
        v-for="(item, _key) in itens"
        :key="_key"
      >
        <v-hover v-slot:default="{ hover }">
          <v-card
            :id="_key"
            class="mx-auto my-auto px-2 d-flex"
            width="352px"
            height="100px"
            :color="hover ? 'grey lighten-2' : 'grey lighten-3'"
            elevation="1"
            :to="item.link"
            :disabled="item.disabled"
          >
            <v-row
              no-gutters
              align="center"
              justify="center"
            >
              <v-col cols="2">
                <v-icon
                  class="my-auto d-flex justify-center"
                  :size="item.icon_size || 40"
                  color="primary"
                >
                  {{ item.icon }}
                </v-icon>
              </v-col>
              <v-col cols="10">
                <v-card-text class="mx-auto my-auto">
                  <div
                    class="subtitle-2"
                    style="word-break: normal"
                  >
                    {{ item.name }}
                  </div>
                  <div class="caption text--secondary">
                    {{ item.description }}
                  </div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';
export default {
  mixins: [profilePermissionsMixin],
  data: () => ({
    itens: []
  }),
  mounted() {
    this.setItens();
  },
  methods: {
    setItens() {
      if (!this.accessReleased('MONITORAMENTO_EM_TEMPO_REAL')) return false;

      if (this.accessReleased('BEACONS_X_EQUIPAMENTOS_MONITORAR')) {
        this.itens.push({
          name: 'Monitorar Equipamentos',
          link: 'monitoramento-em-tempo-real/beacons-x-equipamentos/monitorar',
          description:
            'Monitore a localização de equipamentos da BDGD instalados em subestações.',
          icon: 'mdi-map-marker-radius'
        });
      }

      if (this.accessReleased('BEACONS_X_EQUIPAMENTOS_LISTAR')) {
        this.itens.push({
          name: 'Beacons x Equipamentos',
          link: 'monitoramento-em-tempo-real/beacons-x-equipamentos/listar',
          description:
            'Vincule dispositivos beacons a equipamentos da BDGD instalados em subestações.',
          icon: 'mdi-wifi-marker'
        });
      }
    }
  }
};
</script>
